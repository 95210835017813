
import { Component, Vue, Watch } from 'vue-property-decorator';
import moment from 'moment';
import SuccessCheck from '@/components/SuccessCheck.vue';
import giftCardService from '../services/giftCards/giftCardService';
import userService from '../services/users/userService';

@Component({
  components: {
    SuccessCheck,
  },
})

export default class CodeResultSuccess extends Vue {
  public amountToDeduct = null as any;
  public userInfo = {
    id: '',
    email: '',
    role: [''],
    services: [''],
    verified: false,
  };
  get card(): any | null {
    return this.$store.getters['cardModule/card'];
  }

  mounted(): void {
    if (!this.card) {
      this.$router.push({ name: 'Home' });
    }

    if (!this.card.activated) {
      this.$router.push({ name: 'Home' });
      this.$buefy.toast.open({
        message: 'La carte cadeau n\'est pas activée.',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000,
        queue: false,
      });
    }

    if (!this.card.batch.activated) {
      this.$router.push({ name: 'Home' });
      this.$buefy.toast.open({
        message: 'Le lot de carte cadeau n\'est pas activé.',
        type: 'is-danger',
        position: 'is-bottom',
        duration: 5000,
        queue: false,
      });
    }
  }

  get userId(): string {
    return this.$store.getters['authModule/userId'];
  }

  get cardCreditValueValidationMessage(): string {
    if (!this.card?.isDivisible) {
      return '';
    }
    if (Number.isNaN(this.amountToDeduct)) {
      return 'Veuillez saisir un nombre valide.';
    }
    if (this.amountToDeduct <= 0) {
      return 'Veuillez saisir un montant supérieur à 0.';
    }
    if (this.amountToDeduct > this.card.credit) {
      return 'Veuillez saisir un montant inférieur au montant disponible.';
    }
    if (this.amountToDeduct.toString().includes('.')) {
      const splittedValue = this.amountToDeduct.toString().split('.');
      if (splittedValue[1].length > 2) {
        return 'Veuillez saisir un montant avec 2 chiffres après la virgule.';
      }
    }
    return '';
  }

  async burnCard(): Promise<void> {
    try {
      const infoUser = await userService.findOne(this.userId);
      this.userInfo = {
        // eslint-disable-next-line no-underscore-dangle
        id: infoUser.data._id,
        email: infoUser.data.email,
        role: infoUser.data.role,
        services: infoUser.data.service,
        verified: infoUser.data.verified,
      };
      const formatAmountToDeduct = Number(this.amountToDeduct)
        ? Number(this.amountToDeduct) : undefined;
      const { data } = await
      giftCardService.burn(this.$route.query.uuid as string | null, formatAmountToDeduct as any);
      this.$store.dispatch('cardModule/codeSuccess', {
        card: {
          ...data,
          prevCredit: this.card.credit,
          amountToDeduct: Number(this.amountToDeduct),
        },
      });
      this.$router.push({
        name: 'CardBurnResult',
        query: { result: 'success' },
      });
    } catch (e: any) {
      this.$router.push({
        name: 'CardBurnResult',
        query: { result: 'error' },
      });
      const { data } = e.response;
      const message = Array.isArray(data.message) ? data.message[0] : data.message;
      this.$store.dispatch('cardModule/codeFailure', { error: message });
    }
  }

  @Watch('amountToDeduct')
  onAmountToDeductChange(): void {
    if (Number(this.amountToDeduct) > Number(this.card.credit)) {
      this.amountToDeduct = this.card.credit;
    }
    if (Number(this.amountToDeduct) < 0) {
      this.amountToDeduct = 0;
    }
  }

  formatDate(date: string): string {
    return moment(date).format('DD MMMM YYYY').toString();
  }

  useAllAmount(): void {
    this.amountToDeduct = this.card.credit;
  }
}
