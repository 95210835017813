
import { Component, Vue } from 'vue-property-decorator';
import CodeResultSuccess from '@/components/CodeResultSuccess.vue';
import CodeResultFailure from '@/components/CodeResultFailure.vue';

@Component({
  components: {
    CodeResultSuccess,
    CodeResultFailure,
  },
})

export default class CodeResult extends Vue {
  public result: string | undefined = undefined;
  created(): void {
    // console.log(`Result: ${this.$route.query.result}`);
    this.result = String(this.$route.query.result);
  }
}
