
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class CodeResultFailure extends Vue {
  public error = this.$store.state.cardModule.error;

  mounted(): void {
    // Si refresh alors redirection vers home
    if (!this.error) {
      this.$router.push('/home');
    }
  }

  get hasErrorContractNotAccepted(): boolean {
    // Si erreur contrat pas encore accepté, alors affiche un bouton pour redirigé l'user.
    return this.error.includes('accepter le contrat') && true;
  }

  openContactMail(): void {
    const to = process.env.VUE_APP_MAIL_CONTACT;
    window.location.href = `mailto:${to}?subject=Problème%20carte%20CKDO`;
  }
}
